import { useState, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import storage from '../../storage';
import ExcelFileOrientationImage from '../../assets/images/excelFileOrientation.png';
import ExcelFileFitColumnsImage from '../../assets/images/excelFileFitColumns.png';
import { closeDialog, selectDialogState } from '../../store/slices/dialogSlice';
import { DialogName } from '../../enums';
import UntitledDialog from '../UntitledDialog/UntitledDialog';
import UntitledDialogHeader from '../UntitledDialog/UntitledDialogHeader';
import UntitledDialogFooter from '../UntitledDialog/UntitledDialogFooter';
import LightBulb05 from '../../icons/LightBulb05';

import './PrintExcelFileDemo.scss';

const PrintExcelFileDemoDialog: FC = function PrintExcelFileDemoDialog() {
  const { open } = useSelector(selectDialogState<undefined>(DialogName.PRINT_EXCEL_FILE_DEMO));

  const dispatch = useDispatch();

  const [ignoreDemo, setIgnoreDemo] = useState<boolean>(storage.getShowExcelReportTip() === false);

  const handleShowTipAgainChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    storage.setShowExcelReportTip(!event.target.checked);
    setIgnoreDemo(event.target.checked);
  }, []);

  useEffect(() => {
    if (ignoreDemo === storage.getShowExcelReportTip())
      setIgnoreDemo(!storage.getShowExcelReportTip());
  }, [open, ignoreDemo]);

  const handleCloseDialog = useCallback(() => {
    dispatch(closeDialog(DialogName.PRINT_EXCEL_FILE_DEMO));
  }, [dispatch]);

  return (
    <UntitledDialog
      open={open}
      className="print-excel-file-demo-dialog"
      dialogId={DialogName.PRINT_EXCEL_FILE_DEMO}
      TransitionComponent={Slide}
      transitionDuration={{ enter: 500, exit: 0 }}
    >
      <UntitledDialogHeader
        theme="light-color-outline"
        color="success"
        title="Print Excel File Tips"
        onClose={handleCloseDialog}
        Icon={LightBulb05}
      />

      <div className="dialog-body">
        <div className="row">
          <p>
            1. Press <span>CTRL + P</span>. This opens the print preview.
          </p>
        </div>
        <div className="row">
          <p>
            2. Select <span>Landscape</span> orientation under settings.
          </p>
          <img src={ExcelFileOrientationImage} alt="" />
        </div>
        <div className="row">
          <p>
            3. Select <span>Fit All Columns on One Page</span>.
          </p>
          <img src={ExcelFileFitColumnsImage} alt="" />
        </div>
      </div>
      <UntitledDialogFooter
        type="horizontal-checkbox"
        color="inherit"
        leftButtonText="Cancel"
        checkboxProps={{
          checkBoxProps: {
            onChange: handleShowTipAgainChange,
            checked: ignoreDemo,
          },
          label: 'Dont show again',
        }}
        leftButtonProps={{
          onClick: handleCloseDialog,
        }}
      />
    </UntitledDialog>
  );
};

export default PrintExcelFileDemoDialog;
