import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import AppDrawer from './AppDrawer';
import Main from './AppMain';

import './style.scss';
import PostHogPageviewTracker from '../../resources/Analytics/posthog/PostHogPageviewTracker';

function Layout() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <PostHogPageviewTracker />
      <AppDrawer open={open} toggleDrawer={toggleDrawer} />
      <CssBaseline />
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}

export default Layout;
