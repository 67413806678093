import { Config } from '../../config';
import Links from './Links';
import Calendar from '../../icons/Calendar';
import SwitchHorizontal01 from '../../icons/SwitchHorizontal01';
import DataFlow04 from '../../icons/DataFlow04';
import FileCheck02 from '../../icons/FileCheck02';
import Settings01 from '../../icons/Settings01';
import Bell01 from '../../icons/Bell01';
import { SvgUntitledIconProps } from '../../types/Shared';
import WhatsAppIcon from '../../icons/WhatsAppIcon';

export type NavLinkType = {
  id: string;
  label: string;
  to?: string;
  href?: string;
  Icon?: React.ElementType<SvgUntitledIconProps>;
  requiredFeatures: number[];
  navItems?: NavLinkType[];
};

export const SCHEDULE: NavLinkType = {
  id: 'SCHEDULE',
  label: 'Schedule',
  to: Links.WEEKLY_SCHEDULE_LINK,
  Icon: Calendar,
  requiredFeatures: [],
};

export const REQUESTS: NavLinkType = {
  id: 'REQUESTS',
  label: 'Requests',
  to: Links.REQUESTS_LINK,
  Icon: SwitchHorizontal01,
  requiredFeatures: [Config.FEATURES_ID.EXCHANGE_REQUEST, Config.FEATURES_ID.OFF_DAY],
};

export const MANAGE_SHIFTS: NavLinkType = {
  id: 'MANAGE_SHIFTS',
  label: 'Shifts',
  to: Links.MANAGE_SHIFTS_LINK,
  requiredFeatures: [],
};

export const MANAGE_POSITIONS: NavLinkType = {
  id: 'MANAGE_POSITIONS',
  label: 'Positions',
  to: Links.MANAGE_POSITIONS_LINK,
  requiredFeatures: [],
};

export const MANAGE_EMPLOYEES: NavLinkType = {
  id: 'MANAGE_EMPLOYEES',
  label: 'Employees',
  to: Links.MANAGE_EMPLOYEES_LINK,
  requiredFeatures: [],
};

const MANAGE_NAV: NavLinkType = {
  id: 'MANAGER_NAV',
  label: 'Manage',
  Icon: DataFlow04,
  navItems: [MANAGE_SHIFTS, MANAGE_POSITIONS, MANAGE_EMPLOYEES],
  requiredFeatures: [],
};

export const MANAGER_REPORTING: NavLinkType = {
  id: 'MANAGER_REPORTING',
  label: 'Attendance Report',
  to: Links.MANAGER_REPORTING_LINK,
  Icon: FileCheck02,
  requiredFeatures: [Config.FEATURES_ID.REPORTING],
};

export const NOTIFICATIONS: NavLinkType = {
  id: 'NOTIFICATIONS',
  label: 'Notifications',
  to: Links.NOTIFICATIONS_LINK,
  Icon: Bell01,
  requiredFeatures: [],
};

export const SETTINGS: NavLinkType = {
  id: 'SETTINGS',
  label: 'Settings',
  to: Links.SETTINGS_LINK,
  Icon: Settings01,
  requiredFeatures: [],
};

export const SUPPORT: NavLinkType = {
  id: 'SUPPORT',
  label: 'Whatsapp',
  Icon: WhatsAppIcon,
  href: `https://wa.me/${'+96181177110'}?text=${encodeURIComponent('Hello Avo')}`,
  requiredFeatures: [],
};

const links = {
  upper: [SCHEDULE, REQUESTS, MANAGE_NAV, MANAGER_REPORTING],
  lower: [SUPPORT, SETTINGS],
};

export default links;
