import ReactGA from 'react-ga';
import Mixpanel from 'mixpanel-browser';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import posthog from 'posthog-js';
import pageViews from './pageViews';
import categories from './eventCategories';
import { Constants } from '../../constants';

const NODE_ENV = Constants.NODE_ENV.PRODUCTION;

let juneAnalytics: AnalyticsBrowser;

const init = () => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.init('acad237ec44362c702857f9b91e7c2ed');
    ReactGA.initialize('UA-133343088-1');
    juneAnalytics = AnalyticsBrowser.load({
      writeKey: 'YGxq1gavkkiYT8gf',
    });
    posthog.init('phc_xqe5EyZt6Agz2nOmJ3NBu7W4gQKI7rNdttJ9aj8VnLH', {
      api_host: 'https://us.i.posthog.com',
      capture_pageview: false,
      person_profiles: 'identified_only',
    });
  } else {
    // Mixpanel.init('de87d0bfe83dcf2186bca588958d9269');
  }
};

const setUser = (user: { id: string; name: string; email: string; company_id: number }) => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.identify(user.id);
    Mixpanel.people.set({
      $email: user.email,
      USER_ID: user.id,
      name: user.name,
    });
    juneAnalytics?.identify(user.id, {
      email: user.email,
      name: user.name,
    });

    juneAnalytics?.group(String(user.company_id));
    posthog?.identify(user.id, {
      email: user.email,
    });
    posthog?.group('company', String(user.company_id));

    ReactGA.set({ userId: user.id });
  }
};

const sendAnalyticEvent: (category: string, action?: string, data?: unknown[]) => void = (
  category,
  action = '',
  data = [],
) => {
  if (import.meta.env.MODE === NODE_ENV) {
    ReactGA.event({ category, action, ...data });
    Mixpanel.track(category, { action, ...data });
    juneAnalytics?.track(category, { action, ...data });
  }
};

const trackPageView = (pageName: string) => {
  if (import.meta.env.MODE === NODE_ENV) {
    ReactGA.pageview(window.location.pathname);
    Mixpanel.track('Page View', { page: pageName });
    juneAnalytics?.page(pageName);
  }
};

const logout = () => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.reset();
    juneAnalytics?.reset();
    posthog?.reset();
  }
};

export default {
  setUser,
  sendAnalyticEvent,
  init,
  trackPageView,
  pageViews,
  categories,
  logout,
};
