import { SalesProjectionMethod, SalesProjectionMethodLabel } from '../../enums';
import { Sales } from '../../types/Sales';
import { WeeklyWages } from '../../types/WeeklySchedule';
import { WeekDay, getDate, getWeekDay } from './date';
import generalUtils from './general';

const NUM_OF_DAYS_IN_WEEK = 7;

export type SalePercentage = {
  salesPercentage: string | number;
  isSalesExceedCap: boolean;
};

const calculateTotalActualSales = (salesData: Sales['current_week']) => {
  return salesData.reduce(
    (total, day) => total + day.sales_values.reduce((sum, sale) => sum + sale.value, 0),
    0,
  );
};

const calculateTotalProjectedSales = (data: Sales['previous_week']) => {
  let totalProjectedSales = 0;
  const lastWeekSales = data?.slice?.(7) ?? [];
  const lastTwoWeeksSales = data?.slice?.(0, 7) ?? [];

  lastWeekSales.forEach((day, index) => {
    const daySales = day.sales_values.length
      ? day.sales_values
      : lastTwoWeeksSales[index].sales_values;
    totalProjectedSales += daySales.reduce((accr, sale) => accr + sale.value, 0);
  });

  return totalProjectedSales;
};

const calculateSalesPercentage = (
  payroll: number,
  salesAmount: number,
  cap: number,
): SalePercentage => {
  if (salesAmount === 0) {
    return {
      salesPercentage: 'N/A',
      isSalesExceedCap: false,
    };
  }

  const salesPercentage = generalUtils.toFixedDecimals((payroll / salesAmount) * 100, 2);
  const isSalesExceedCap = salesPercentage > cap;

  return {
    salesPercentage,
    isSalesExceedCap,
  };
};

export const getProjectedSalesComputation = (data: Sales) =>
  data.current_week.reduce(
    (accr, day) => {
      const date = getDate(day.date).format('ddd');
      if (day.sales_projection_computation === SalesProjectionMethod.COPY_FROM_LAST_WEEK) {
        accr[SalesProjectionMethodLabel.COPY_FROM_LAST_WEEK].push(date);
      } else {
        accr[SalesProjectionMethodLabel.COMPUTE_TOTAL_SALES_OF_LAST_WEEK].push(date);
      }
      return accr;
    },
    {
      [SalesProjectionMethodLabel.COPY_FROM_LAST_WEEK]: [],
      [SalesProjectionMethodLabel.COMPUTE_TOTAL_SALES_OF_LAST_WEEK]: [],
    } as Record<SalesProjectionMethodLabel, string[]>,
  );

export const getActualSalesPercentages = (
  data: Sales['current_week'],
  payroll: number,
  cap: number,
): SalePercentage => {
  if (data.length) {
    const totalSales = calculateTotalActualSales(data);
    return calculateSalesPercentage(payroll, totalSales, cap);
  }

  return {
    salesPercentage: 'N/A',
    isSalesExceedCap: false,
  };
};

export const getProjectedSalesPercentages = (
  data: Sales['previous_week'],
  payroll: number,
  cap: number,
) => {
  if (data.length) {
    const totalSales = calculateTotalProjectedSales(data);
    return calculateSalesPercentage(payroll, totalSales, cap);
  }

  return {
    salesPercentage: 'N/A',
    isSalesExceedCap: false,
  };
};

export const getWeekDaysSalesCap = (sales: Sales['current_week'], weekdays: WeekDay[]) =>
  sales.reduce(
    (accr, { sales_percentage_cap: cap }, index) => ({
      ...accr,
      [cap]: accr[cap]
        ? [...accr[cap], weekdays[index].value.toLowerCase()]
        : [weekdays[index].value.toLowerCase()],
    }),
    {} as Record<string, string[]>,
  );

export type ActualSalesRowData = {
  amount: number | null;
  isExceedCap: boolean;
  salesPercentage: string | number | null;
}[];

export const getActualSalesRowData = (
  sales: Sales['current_week'],
  payroll: WeeklyWages,
): ActualSalesRowData =>
  sales.map((day) => {
    const amount = day.sales_values.length
      ? day.sales_values.reduce((prev, sale) => prev + sale.value, 0)
      : null;
    if (amount === null) return { amount, isExceedCap: false, salesPercentage: null };
    const weekday = getWeekDay(day.date);
    const wages = payroll.find((wage) => wage.weekday.weekday === weekday);
    const result = calculateSalesPercentage(
      (wages?.fixed_wages || 0) + (wages?.other_wages || 0),
      amount,
      day.sales_percentage_cap,
    );

    return {
      amount,
      isExceedCap: result.isSalesExceedCap,
      salesPercentage: result.salesPercentage,
    };
  });

export type ProjectedSales = {
  amount: number;
  isExceedCap: boolean;
  salesPercentage: string | number | null;
}[];

export const getProjectedSales = (data: Sales, payroll: WeeklyWages): ProjectedSales => {
  const totalProjectedSales = calculateTotalProjectedSales(data.previous_week);
  const projectedSales: ProjectedSales = [];

  data.current_week.forEach((day, index) => {
    let amount: number = 0;
    if (day.sales_projection_computation === SalesProjectionMethod.COPY_FROM_LAST_WEEK) {
      amount =
        data.previous_week?.[index + 7]?.sales_values?.reduce?.(
          (accr, current) => accr + current.value,
          0,
        ) ?? 0;
    } else if (
      day.sales_projection_computation === SalesProjectionMethod.COMPUTE_TOTAL_SALES_OF_LAST_WEEK
    ) {
      amount = totalProjectedSales / NUM_OF_DAYS_IN_WEEK;
    }

    const weekday = getWeekDay(day.date);
    const wages = payroll.find((wage) => wage.weekday.weekday === weekday);
    const result = calculateSalesPercentage(
      (wages?.fixed_wages || 0) + (wages?.other_wages || 0),
      amount,
      day.sales_percentage_cap,
    );

    projectedSales.push({
      amount,
      salesPercentage: result.salesPercentage,
      isExceedCap: result.isSalesExceedCap,
    });
  });
  return projectedSales;
};

export default {
  getProjectedSalesComputation,
  getProjectedSales,
  getActualSalesPercentages,
  getProjectedSalesPercentages,
  getWeekDaysSalesCap,
  getActualSalesRowData,
};
