import { Components, Theme } from '@mui/material';
import colorPalette from '../themeColorPalette';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiSkeleton: {
    styleOverrides: {
      root: () => ({
        backgroundColor: colorPalette.grey[100],
        '&::after': {
          animation: 'trans 1.3s linear 0s infinite',
        },
        '@keyframes trans': {
          '0%': {
            transform: 'translateX(-100%)',
          },

          '50%': {
            transform: 'translateX(-40%)',
          },

          '100%': {
            transform: 'translateX(100%)',
          },
        },
      }),
    },
  },
};

export default components.MuiSkeleton;
