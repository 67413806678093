import { Components, Theme } from '@mui/material';
import colors from '../themeColorPalette';
import themeShadows from '../themeShadows';

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        marginTop: '6px',
        color: colors.grey[900],

        '.MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.grey[300],
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6F6F6',
          border: `1px solid ${colors.primary[300]}`,
        },

        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          boxShadow: themeShadows.xs,
          border: `1px solid ${colors.error[300]}`,
        },

        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
          border: `1px solid ${colors.error[300]}`,
        },

        svg: {
          color: colors.grey[500],
          width: 20,
          height: 20,
        },

        '&.Mui-error svg': {
          color: colors.error[500],
        },

        '&.Mui-disabled': {
          backgroundColor: colors.grey[100],
          '.MuiOutlinedInput-notchedOutline': {
            boxShadow: themeShadows.xs,
            borderColor: colors.grey[300],
          },
        },
      },
    },
  },
};

export default components.MuiOutlinedInput;
