import { Components, Theme } from '@mui/material';
import colors from '../themeColorPalette';
import themeShadows from '../themeShadows';
import untitledTypography from '../themeTypography';

const muiSelectStyles: React.CSSProperties = {
  ...(untitledTypography.text.md.regular as React.CSSProperties),
};

const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
      size: 'small',
      fullWidth: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.size === 'small' && {
          '.MuiSelect-select': {
            padding: '8px 12px',
            ...muiSelectStyles,
          },
        }),

        ...(ownerState.size === 'medium' && {
          '.MuiSelect-select': {
            padding: '10px 14px',
            ...muiSelectStyles,
          },
        }),

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E6F6F6',
          border: `1px solid ${colors.primary[300]}`,
        },

        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          boxShadow: themeShadows.xs,
          border: `1px solid ${colors.error[300]}`,
        },

        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
          border: `1px solid ${colors.error[300]}`,
        },

        '.MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },

        svg: {
          top: '50%',
          transform: 'translateY(-50%)',
        },

        '&.Mui-error .MuiInputAdornment-root svg': {
          color: colors.error[500],
        },
      }),
    },
  },
};

export default components.MuiSelect;
