import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { PopperPlacementType } from '@mui/material/Popper';
import { TextFieldProps } from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeView } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import useSettings from '../../hooks/useSettings';
import utils from '../../shared/utils';
import TimeInputTextField from './TimeInputTextField';

type Meridian = 'AM' | 'PM';

export type TimeInputProps = {
  showSeconds?: boolean;
  valueFormat?: 'HH:mm' | 'hh:mm' | 'hh:mm:ss' | 'HH:mm:ss';
  readOnly?: boolean;
  date?: string;
  inputRef?: React.ForwardedRef<HTMLDivElement>;
  popperPlacement?: PopperPlacementType;
} & TextFieldProps;

const TimeInput = function TimeInput(props: TimeInputProps) {
  const { is_12hr: isHours12 } = useSettings();
  const {
    name,
    date,
    inputRef,
    showSeconds,
    label,
    value,
    onChange,
    readOnly,
    valueFormat,
    popperPlacement,
    ...rest
  } = props;
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [meridian, setMeridian] = useState<Meridian | undefined>();

  const today = useMemo(() => utils.date.now(false, 'YYYY-MM-DD'), []);

  const time: null | Dayjs = useMemo(() => {
    const stringValue = (value || '') as string;
    const valueLength = stringValue.length;

    if (!valueLength) return null;

    const format = 'HH:mm'.slice(0, valueLength);
    if (valueLength >= 5) return dayjs(`${date || today} ${value}`);

    return valueLength
      ? dayjs(`${date || today} ${value} ${meridian}`, `YYYY-MM-DD ${format} A`)
      : null;
  }, [date, meridian, today, value]);

  const timeMeridian = useMemo(
    () => (time?.isValid() ? (time.format('A').toUpperCase() as Meridian) : undefined),
    [time],
  );

  const textInputRef = useRef<HTMLDivElement | null>(null);

  const textValue = useMemo(() => {
    const stringValue = (value || '') as string;
    const valueLength = stringValue.length;
    const format = isHours12 ? 'hh:mm' : 'HH:mm';

    // length > 1 to avoid convert 0 to 12 in case pm merdian is selected
    const result =
      time?.isValid() && valueLength > 1 ? time.format(format.slice(0, valueLength)) : value;

    return result;
  }, [isHours12, time, value]);

  const handleTimePickerChange = useCallback(
    (val: unknown) => {
      if (val) {
        const timeValue: Dayjs = val as Dayjs;
        if (time?.isValid()) {
          setMeridian(timeValue.format('A').toUpperCase() as Meridian);
        }
        onChange?.({
          target: { value: timeValue.format(valueFormat), name },
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        onChange?.({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [name, onChange, time, valueFormat],
  );

  const handleTextFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.type === 'blur') {
        return;
      }
      const timeString = e.target.value;
      const numOfChars = timeString.length;
      if (numOfChars > 1) {
        // length > 1 to avoid convert 0 to 12 in case pm merdian is selected
        const outputFormat = numOfChars >= 5 ? valueFormat : valueFormat?.slice(0, numOfChars);
        const format = (isHours12 ? 'hh:mm' : 'HH:mm')?.slice(0, numOfChars);
        const timeInDayjs = dayjs(`${timeString}`, format);
        const newMerdian = isHours12 || !timeInDayjs.isValid() ? meridian : timeInDayjs.format('A');

        if (newMerdian !== meridian) setMeridian(newMerdian as Meridian);
        const newValue = date
          ? dayjs(`${date} ${timeString} ${newMerdian}`, `DD-MM-YYYY ${format} A`).format(
              outputFormat,
            )
          : dayjs
              .utc(
                `${utils.date.now(true, 'DD-MM-YYYY')} ${timeString} ${newMerdian}`,
                `DD-MM-YYYY ${format} A`,
              )
              .format(outputFormat);
        onChange?.({
          target: {
            value: newValue,
            name,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        onChange?.(e);
      }
    },
    [date, isHours12, meridian, name, onChange, valueFormat],
  );

  const views: TimeView[] = useMemo(
    () => (showSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes']),
    [showSeconds],
  );

  const handleChangeMerdian = useCallback(
    (newMerdian: Meridian) => {
      setMeridian(newMerdian);
      if (value) {
        if (meridian !== newMerdian)
          onChange?.({
            target: {
              name,
              value: date
                ? dayjs(`${date} ${value}`, 'DD-MM-YYYY HH:mm')
                    .add(newMerdian === 'AM' ? -12 : 12, 'hours')
                    .format(valueFormat)
                : dayjs
                    .utc(`${utils.date.now(true, 'DD-MM-YYYY')} ${value}`, 'DD-MM-YYYY HH:mm')
                    .add(newMerdian === 'AM' ? -12 : 12, 'hours')
                    .format(valueFormat),
            },
          } as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [date, meridian, name, onChange, value, valueFormat],
  );

  useEffect(() => {
    if (
      (timeMeridian &&
        meridian &&
        timeMeridian !== meridian &&
        ((value || '') as string).length >= 5) ||
      (timeMeridian && !meridian)
    ) {
      setMeridian(timeMeridian);
    }
  }, [meridian, timeMeridian, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{ overflow: 'inherit' }} components={['TimePicker']}>
        <TimePicker
          open={showTimePicker}
          readOnly={readOnly}
          label={label}
          value={time}
          onChange={handleTimePickerChange}
          ampm={!!isHours12}
          format={isHours12 ? 'hh:mm' : 'HH:mm'}
          views={views}
          onClose={() => setShowTimePicker(false)}
          onAccept={() => setShowTimePicker(false)}
          slotProps={{
            popper: {
              placement: popperPlacement ?? 'auto',
              anchorEl: textInputRef.current,
            },
            inputAdornment: {
              position: 'start',
            },
            textField: {
              style: { display: 'none' },
            },
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            ...(showSeconds ? { seconds: renderTimeViewClock } : {}),
          }}
        />

        <TimeInputTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          ref={null}
          inputRef={inputRef}
          name={name}
          value={textValue}
          onChange={handleTextFieldChange}
          label={label}
          hours12={!!isHours12}
          setShowTimePicker={setShowTimePicker}
          meridian={meridian}
          onMeridianChange={handleChangeMerdian}
          InputProps={{
            ...rest.InputProps,
            ref: textInputRef,
            name,
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

TimeInput.defaultProps = {
  popperPlacement: 'auto',
  showSeconds: false,
  valueFormat: 'HH:mm',
  readOnly: false,
  date: undefined,
  inputRef: () => {},
};

export default TimeInput;
