import { FC, Ref, forwardRef } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import { NavLink as NavLinkBase } from 'react-router-dom';
import { NavLinkType } from './NavLinksConfig';
import ChevronDown from '../../icons/ChevronDown';
import colorPalette from '../../styles/Theme/themeColorPalette';

type NavLinkPropTypes = {
  className?: string;
  to: string;
};

const NavLink = forwardRef(function NavLink(props: NavLinkPropTypes, ref: Ref<HTMLAnchorElement>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NavLinkBase ref={ref} {...props} />;
});

NavLink.defaultProps = {
  className: '',
};

type NavLinkItemPropTypes = {
  link: NavLinkType;
  open: boolean;
  className?: string;
  onClick?: () => void;
  isDropDownOpened?: boolean;
};

const NavLinkItem: FC<NavLinkItemPropTypes> = function NavLinkItem(props) {
  const { link, open, className, onClick, isDropDownOpened } = props;
  const linkProps = {
    ...(!!link.to?.length && { to: link.to, component: NavLink }),
    ...(!!link.href?.length && {
      component: 'a',
      href: link.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }),
    ...(!link.to?.length && !link.href && { component: 'span' }),
  };

  const { Icon, label } = link;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItem onClick={onClick} disablePadding classes={{ root: className }} {...linkProps}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: 'flex-start',
          px: 1.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {!!Icon && <Icon />}
        </ListItemIcon>

        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
        {!!link.navItems?.length && (
          <Box
            sx={{
              transform: isDropDownOpened ? 'rotate(180deg)' : '',
              visibility: open ? 'visible' : 'hidden',
              opacity: open ? '1' : '0',
              transition: 'all 0.1s ease-in-out',
            }}
          >
            <ChevronDown
              stroke={colorPalette.grey[500]}
              strokeWidth="1.66667"
              width="20"
              height="20"
            />
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
};

NavLinkItem.defaultProps = {
  className: '',
  onClick: () => null,
  isDropDownOpened: false,
};

export default NavLinkItem;
